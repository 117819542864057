import React, { useMemo } from 'react';
import { CBadge } from '@coreui/react-pro';
import { CellProps } from '@smart-table/types';
import { useFormatTranslation } from '@hooks';

export default function StateCell(props: CellProps) {
  const { t } = useFormatTranslation();

  const option = useMemo(() =>
      props.options?.find(x => x.value == props.item[props.columnKey]),
    [props.options, props.item]);
  return (
    <td>
      <CBadge color={option?.color}>
        {t(option?.label ?? "") ?? option?.label}
      </CBadge>
    </td>
  );
}