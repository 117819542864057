import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CFormSelect } from '@coreui/react-pro';
import { ColumnFilterProps } from '@smart-table/types';
import { IOption } from '@types';
import storage from '@storage';
import { useFormatTranslation } from '@hooks';

const defaultOption = { label: '-', text: '-', selected: true, value: '' } as IOption;

export default function StateFilter(props: ColumnFilterProps) {
  const value = useSelector(storage.smartTable.selectFilter<string>(props.tableName, props.columnKey));
  const [options, setOptions] = useState<IOption[]>([defaultOption, ...(props.options ?? [])]);

  const { t } = useFormatTranslation();
  
  useEffect(() => {
    const newOptions = options?.map((item: IOption) => {
      item.selected = item.value === value;
      return item;
    }) ?? [defaultOption];
    setOptions(newOptions);
  }, [value]);

  return (
    <CFormSelect
      size='sm'
      style={{ height: 31, paddingTop: 4, paddingBottom: 4 }}
      value={value}
      onChange={e => {
        const val = e.target.value !== '-' ? e.target.value : '';
        props.onChange(val);
      }}
    >
      {options.map((option, i) => (
        <option value={option.value} key={i}>
          {(t(option.text ?? "") ?? option.text) ?? (t(option.label ?? "") ?? option.label)}
        </option>
      ))}
    </CFormSelect>
  );
}