import React from 'react';
import { CellProps } from '@smart-table/types';
import { CBadge } from '@coreui/react-pro';

export default function DateTimeCell(props: CellProps) {
  const date = convertToDateTime(props.item[props.columnKey]);
  return (
    <td>
      <CBadge style={{color: 'var(--cui-table-color)'}} >
        {date}
      </CBadge>
    </td>
  );
}

const convertToDateTime = (date?: string) => {
  if (!date) {
    return '';
  }
  const _date = new Date(Date.parse(date));
  return `${_date?.toLocaleDateString()} ${_date?.toLocaleTimeString()}`;
};