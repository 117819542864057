import React from 'react';
import { CellProps } from '@smart-table/types';

export default function TimeStampCell(props: CellProps) {
  const date = new Date(props.item[props.columnKey]);
  const value = `${date?.toLocaleTimeString("ru-RU")} ${date?.toLocaleDateString("ru-RU")}`
  return (
    <td>
      {value}
    </td>
  );
}