import React, { ChangeEventHandler, useRef } from 'react';
import { CFormInput, CFormLabel } from '@coreui/react-pro';
import { FormLabel } from '@component';
import { useDispatch } from 'react-redux';
import storage from '@storage';

interface FormInputProps {
  type?: string;
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  defaultValue?: string | number | readonly string[];
  value?: string | number | string[];
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  prompt?: string;
  size?: 'sm' | 'lg';
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const _style: React.CSSProperties = {
  fontSize: 12,
  fontStyle: "italic"
};

export default function FormInput(props: FormInputProps) {
  const dispatch = useDispatch();
  const input = useRef<HTMLInputElement>(null);
  
  return (
    <>
      <FormLabel
        hidden={props.hidden}
        required={props.required}
        description={props.description}>
        {props.label}
      </FormLabel>
      <CFormInput
        ref={input}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        value={props.value}
        required={props.required}
        readOnly={props.readOnly}
        disabled={props.disabled}
        hidden={props.hidden}
        maxLength={props.maxLength}
        min={props.min}
        max={props.max}
        size={props.size}
        onChange={e => {
          if (input.current) {
            if (e.target 
              && props.maxLength
              && e.target.value.length >= props.maxLength) {
                dispatch(storage.toast.setError(`Максимальная длина контента не должна быть больше ${props.maxLength}`));
            }
          }
          
          props.onChange?.call(null, e);
        }}
      />
      {props.prompt && (
      <CFormLabel style={_style} hidden={props.hidden}>
          {props.prompt}
      </CFormLabel>
      )}
    </>
  );
}